import React, { Component } from "react";
import { graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import grievanceStyle from "ps-assets/jss/grievanceStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import classNames from "classnames";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { Helmet } from "react-helmet";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

let Url = process.env.BASE_URL;
var apiBaseUrl = process.env.API_URL;

// var table_data = [];
class ListProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policy_no: ``,
      pan_no: ``,
      policy_holder_name: ``,
      dob: ``,
      customer_name: ``,
      address: ``,
      status: ``,
      response_data: [],
      details: {}
    };
  }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true
      },
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: `red`
          },
          paper: {
            boxShadow: `none`,
            paddingBottom: `20px`
          },
          responsiveScroll: { maxHeight: `auto !important` }
        },
        MUIDataTableHeadCell: {
          root: {
            fontSize: 17,
            color: `black`
          }
        }
      }
    });
  handleChange = e => {
    if (e.target.id === `mobile`) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else if (
      e.target.id === `customer_name` ||
      e.target.id === `policy_holder_name`
    ) {
      if ((`nice`, e.target.value.search(`^[a-zA-Z. ']*$`) === 0)) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };
  handleSubmit = async e => {
    var rowDetials = [];
    e.preventDefault();
    if (
      this.state.policy_no == `` &&
      this.state.pan_no == `` &&
      this.state.policy_holder_name == `` &&
      this.state.address == `` &&
      this.state.dob == `` &&
      this.state.customer_name == ``
    ) {
      this.setState({
        status: `error`
      });
    } else {
      let url = `${apiBaseUrl}form/claim-proposal?`;

      let { policy_no, pan_no, policy_holder_name, address, dob } = this.state;

      var queryString = this.objToQueryString({
        policy_no: policy_no,
        pan_no: pan_no,
        policy_holder_name: policy_holder_name,
        address: address,
        dob: dob,
        pageNo: ` 1 `,
        totalRecord: ` 10 `
      });
      url += queryString;
      let res = await fetch(url);
      let resJson = await res.json();

      if (res.status !== 200) {
        this.setState({
          status: `fail`
        });
      } else if (resJson) {
        if (!resJson.error) {
          this.setState({
            policy_no: ``,
            pan_no: ``,
            policy_holder_name: ``,
            dob: ``,
            customer_name: ``,
            address: ``,
            status: `success`,
            response_data: resJson
          });
          resJson.success.map(data => {
            if (
              data.policy_no != `` &&
              data.customer_name != `` &&
              data.claims_id != `` &&
              data.unclaimed_premium_amount != undefined
            ) {
              var complete_data = {};
              complete_data[`policy_no`] = data.policy_no;
              complete_data[`customer_name`] = data.customer_name;
              complete_data[`claims_id`] = data.claims_id;
              complete_data[`unclaimed_premium_amount`] =
                data.unclaimed_premium_amount;
              rowDetials.push(complete_data);
            }
          });
          var details = {};
          details.rows = rowDetials;
          this.setState({
            details: details
          });
        } else {
          this.setState({
            status: `fail`
          });
        }
      }
    }
  };

  handleDate = value => {
    this.setState({ dob: value });
  };

  handleDateString = e => {
    var dob = e;
    if (this.state.dob.length > 10) {
      this.setState({
        dob: this.state.dob.substr(0, 10)
      });
    } else {
      this.setState({
        dob: dob
      });
    }
  };
  setStatus = () => {
    this.setState({
      status: ``
    });
  };
  objToQueryString = obj => {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj[key] && obj[key].trim() != ``)
        keyValuePairs.push(key + `=` + obj[key]);
    }
    return keyValuePairs.join(`&`);
  };

  render = () => {
    const columns = [
      {
        name: `S-no`,
        label: `S.No`,
        options: {
          filter: true,
          sort: true,
          style: { fontSize: 100 }
        }
      },
      {
        name: `product_name`,
        label: `Product Name`,
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: `uin`,
        label: `UIN`,
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: `dateoflaunch`,
        label: `Date of Launch`,
        options: {
          filter: true,
          sort: false
        }
      }
    ];
    const options = {
      selectableRows: false,
      filter: false,
      search: false,
      sort: false,
      download: false,
      print: false,
      viewColumns: false,
      responsive: `scroll`,
      pagination: false
    };
    let rows = [
      // [
      //   ``,
      //   `Star Group Hospital Cash Insurance Policy`,
      //   `SHAHLGP029V011920`,
      //   `10.06.2020`
      // ],
      // [
      //   ``,
      //   `Star Group COVID Top-up`,
      //   `SHAHLGP21538V012021`,
      //   `15.10.2020`
      // ],
      [
        `1`,
        `Family Accident Care Insurance Policy`,
        `SHAHLIP21042V012021`,
        `22.08.2020`
      ],
      [
        `1`,
        `Star Group COVID Insurance Policy`,
        `SHAHLGP22147V022122`,
        `31.08.2021`
      ],
      [
        1,
        `Arogya Sanjeevani Policy, Star Health and Allied Insurance Co Ltd`,
        `SHAHLIP22027V032122`,
        `02.04.2020`
      ],
      // [
      //   `1`,
      //   `Star Novel Coronavirus (nCoV) (COVID-19) Insurance Policy (Pilot Product)`,
      //   `SHAHLIP21047V022021`,
      //   `12.06.2020`
      // ],
      [
        `1`,
        `Star Out patient Care Insurance Policy`,
        `SHAHLIP22231V012122`,
        `13.04.2022`
      ],
      [`1`, `Young Star Insurance Policy`, `SHAHLIP22036V042122`, `16.03.2020`],
      [
        `1`,
        `Medi Classic Insurance Policy (Individual)`,
        `SHAHLIP23037V072223`,
        `22.07.2022`
      ],
      [
        `1`,
        `Star Hospital Cash Insurance Policy`,
        `SHAHLIP20046V011920`,
        `04.09.2019`
      ],
      [
        `2`,
        `Star Micro Rural and Farmers Care`,
        `SHAHMIP22038V032122`,
        `04.09.2019`
      ],
      [
        `3`,
        `Ayushman Bharat Pradhan Mantri Jan Arogya Yojana(ABPMJAY) - Government of Puducherry`,
        `SHAHGSP20026V011920`,
        `29.07.2019`
      ],
      [
        `3`,
        `Star Group Top-up`,
        `SHAHLGP21567V012021`,
        `01.01.2021`
      ],
      // [
      //   `4`,
      //   `Star Group Overseas Travel Insurance Policy`,
      //   `SHATGOP20032V011920`,
      //   `11.07.2019`
      // ],
      [
        `5`,
        `Super Surplus Insurance Policy`,
        `SHAHLIP22035V062122`,
        `12.04.2021`,
      ],
      [
        `6`,
        `Star Group Criticare Platinum`,
        `SHAHLGP19049V011819`,
        `01.03.2019`
      ],
      [
        `7`,
        `Star Group Health Insurance Policy For Bank Customers`,
        `SHAHLGP21290V022021`,
        `27.02.2019`
      ],
      [
        `8`,
        `Star Group Health Insurance Policy Gold (For Bank Customers)`,
        `SHAHLGP21058V022021`,
        `01.10.2020`
      ],
      [
        `9`,
        `Senior Citizens Red Carpet Health Insurance Policy`,
        `SHAHLIP22199V062122`,
        `12.02.2022`
      ],
      [`10`, `Star Group Criticare Gold`, `SHAHLGP19048V011819`, `24.12.2018`],
      [
        `11`,
        `Star Super Surplus (Floater) Insurance Policy`,
        `SHAHLIP22034V062122`,
        `12.04.2021`,
      ],
      [
        `12`,
        `Diabetes Safe Insurance Policy`,
        `SHAHLIP23081V082223`,
        `01.01.2023`
      ],
      [
        `13`,
        `Star Group Health Insurance`,
        `SHAHLGP23021V032223`,
        `18.05.2022`
      ],
      [
        `14`,
        `Group Accident Insurance Policy`,
        `SHAHLGP18123V011718`,
        `13.06.2018`
      ],
      [
        `15`,
        `Star Cardiac Care Insurance Policy`,
        `SHAHLIP22032V052122`,
        `04.05.2018`
      ],
      [
        `16`,
        `POS-Accident Care Individual Insurance Policy`,
        `SHAHPAIP18070V031718`,
        `01.03.2018`
      ],
      // [
      //   `17`,
      //   `POS-Medi Classic Insurance Policy (Individual)`,
      //   `SHAHLIP23037V072223`,
      //   `22.07.2022`
      // ],
      // [
      //   `18`,
      //   `POS- Star Travel Protect Insurance Policy`,
      //   `IRDA/NL-HLT/SHAI/P-T/V.I/140/13-14`,
      //   `01.03.2018`
      // ],
      // [
      //   `19`,
      //   `POS-Family Health Optima Insurance Plan`,
      //   `SHAHLIP23164V072223`,
      //   `01.02.2023`
      // ],
      [`20`, `Star Special Care`, `SHAHLIP21243V022021`, `17.01.2018`],
      [
        `21`,
        `Star Health Gain Insurance Policy`,
        `SHAHLIP21262V032021`,
        `27.12.2017`
      ],
      [
        `22`,
        ` Medi Classic Accident Care (Individual) Insurance Policy`,
        `SHAHLIP23079V062223`,
        `13.12.2022`
      ],
      [
        `23`,
        `Classic Group Health Insurance`,
        `SHAHLGP21239V022021`,
        `06.12.2017`
      ],
      // [
      //   `24`,
      //   `Privilege Group Health Insurance`,
      //   `SHAHLGP21268V0122021`,
      //   `27.11.2017`
      // ],
      [
        `31`,
        `Family Health Optima Accident Care Policy`,
        `SHAHLIP22102V052122`,
        `01.11.2021`
      ],
      // [
      //   `32`,
      //   `Star Cancer Care Gold (Pilot Product)`,
      //   `SHAHLIP21216V022021`,
      //   `24.10.2017`
      // ],
      [
        `33`,
        `Accident Care Individual Insurance Policy`,
        `IRDAI/HLT/SHAI/P-P/V.III/134/2017-18`,
        `15.07.2017`
      ],
      [
        `34`,
        `Family Health Optima Insurance Plan`,
        `SHAHLIP23164V072223`,
        `01.02.2023`
      ],
      // [
      //   `35`,
      //   `Star Care Micro Insurance Policy`,
      //   `SHAHLIP21180V022021`,
      //   `07.04.2017`
      // ],
      [
        `36`,
        `Accident Care (Group) Insurance`,
        `IRDAI/HLT/SHAI/P-H/V.II/102/2015-16`,
        `01.04.2016`
      ],
      [
        `37`,
        `Star Comprehensive Insurance Policy`,
        `SHAHLIP22028V072122`,
        `22.11.2019`
      ],
      // [
      //   `39`,
      //   `Star Family Delite Insurance Policy`,
      //   `SHAHLIP21178V022021`,
      //   `05.08.2012`
      // ],
      // [
      //   `40`,
      //   `Star Criticare Plus Insurance Policy`,
      //   `SHAHLIP21179V022021`,
      //   `14.09.2009`
      // ],
      [
        `41`,
        `Accident Trauma Care Insurance Policy (Individual)`,
        `IRDA/NL-HLT/SHAI/P-P/V.I/136/13-14`,
        `08.12.2006`
      ],
      [
        `42`,
        `Accident Trauma Care Insurance Policy ( Group)`,
        `IRDA/NL-HLT/SHAI/P-H/V.I/104/13-14`,
        `08.12.2006`
      ],
      // [
      //   `43`,
      //   `Star True value Health Insurance Policy`,
      //   `SHAHLIP21177V022021`,
      //   `29.11.2006`
      // ],
      [
        `44`,
        `Star Student Travel Protect Insurance Policy`,
        `IRDA/NL-HLT/SHAI/P-T/V.I/142/13-14`,
        `20.11.2006`
      ],
      [
        `45`,
        `Star Corporate Travel Protect Insurance Policy`,
        `IRDA/NL-HLT/SHAI/P-T/V.I/143/13-14`,
        `22.06.2006`
      ],
      [
        `46`,
        `Star Travel Protect Insurance Policy`,
        `IRDA/NL-HLT/SHAI/P-T/V.I/140/13-14`,
        `25.05.2006`
      ],
      // [
      //   `47`,
      //   `College Student Care Insurance`,
      //   `IRDA/NL-HLT/SHAI/P-H/V.I/101/13-14`,
      //   `04.05.2006`
      // ],
      // [
      //   `48`,
      //   `School Student Care Insurance`,
      //   `IRDA/NL-HLT/SHAI/P-H/V.I/103/13-14`,
      //   `26.04.2006`
      // ],
      [
        `49`,
        `Star Net Plus`,
        `SHAHLGP21267V022021`,
        `05.08.2011`
      ],
      [
        `50`,
        `Group Arogya Sanjeevani Policy, Star Health and Allied Insurance Co Ltd`,
        `SHAHLGP22041V022122`,
        `01.12.2021`
      ],
      [
        `51`,
        `Saral Suraksha Bima, Star Health and Allied Insurance Co Ltd`,
        `SHAPAIP22039V022122`,
        `01.04.2021`
      ],
      // [
      //   `52`,
      //   `Mashak (Mosquito) Rakshak, Star Health and Allied Insurance Co. Ltd.`,
      //   `UIN`,
      //   `25.03.2021`
      // ],
      [
        `53`,
        `Star Cardiac Care Insurance Policy-Platinum`,
        `SHAHLIP22033V022122`,
        `26.07.2021`
      ],
      [
        `54`,
        `Star Cancer Care Platinum Insurance Policy`,
        `SHAHLIP22031V022122`,
        `26.07.2021`
      ],
      [
        `55`,
        `Star Critical Illness Multipay Insurance Policy`,
        `SHAHLIP22140V012122`,
        `03.02.2022`
      ],
      [
        `56`,
        `Star Group Critical illness multipay insurance policy`,
        `SHAHLGP22134V012122`,
        `20.12.2021`
      ],
      [
        `57`,
        `Star Women Care Insurance Policy`,
        `SHAHLIP23132V022223`,
        `08.03.2022`
      ],
      [
        `58`,
        `Star Health Premier Insurance Policy`,
        `SHAHLIP22226V012122`,
        `18.03.2022`
      ],
      [
        `59`,
        `Star Group Health Insurance Benefit Plus`,
        `SHAHLGP22213V012122`,
        `01.04.2022`
      ],
      [
        `60`,
        `Star Health Assure Insurance Policy`,
        `SHAHLIP23131V022223`,
        `09.05.2022`
      ],
      [
        `61`,
        `Star Extra Protect - Add on Cover`,
        `SHAHLIA23061V012223`,
        `19.08.2022`
      ],
      [
        `62`,
        `Star Group Health Insurance Policy – Platinum`,
        `SHAHLGP23015V012223`,
        `27.10.2022`
      ],
      [
        `63`,
        `Star Group Health Insurance Benefit Plus - Platinum`,
        `SHAHLGP23146V012223`,
        `06.01.2023`
      ],
        
    ];

    rows = rows
      .sort(
        (a, b) =>
          new Date(
            b[3]
              .split(`.`)
              .reverse()
              .join(`-`)
          ) -
          new Date(
            a[3]
              .split(`.`)
              .reverse()
              .join(`-`)
          )
      )
      .map((a, key) => {
        a.shift();
        a.unshift(key + 1);
        a[3] = a[3].split(`.`).join(`-`);
        return a;
      });

    const { classes, data } = this.props;
    var image =
      data.allStrapiImages.edges[0].node.content.childImageSharp.fluid;
    return (
      <Layout image1={image} image2={image} small={true}>
        <Helmet key="helmetTags">
          <title>List of Products | StarHealth.in</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <link
              rel="canonical"
              href={Url + `list-products`}
            />
            <meta name="title" content="List of Products | StarHealth.in" />
            <meta name="twitter:title" content="List of Products | StarHealth.in" />
            <meta
              name="description"
              content="List of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products available in Star Health"
            />
            <meta
              name="keywords"
              content="Star Health Insurance Plans,Insurance Policy,Star Health Products,Health Insurance Specialist in India, Star Health and Allied Insurance Company Limited, Chennai, India"
            />
            <meta property="og:title" content="List of Products | StarHealth.in" />
            <meta property="og:description" content="List of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products available in Star Health" />
            <meta property="og:url" content={Url + `list-products`}/>
            <meta name="List of Products | StarHealth.in" />
            <meta name="twitter:description" content="List of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products available in Star Health" />
            <meta property="twitter:url" content={Url + `list-products`}/>
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: `100px` }}
        >
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer className={classes.container}>
                <GridItem
                  md={9}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                >
                  {/* <h2 className={`${classes.title}`}>List of Products</h2> */}
                </GridItem>
                <GridItem
                  md={10}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                >
                  <div style={{ maxWidth: `100%`, paddingTop: `40px` }}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <MUIDataTable
                        data={rows}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Layout>
    );
  };
}

export default withStyles(grievanceStyle)(ListProducts);

ListProducts.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export const termsBenefit = graphql`
  query ListProducts {
    allStrapiImages(filter: { category: { eq: "list_of_products" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
